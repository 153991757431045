import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "intersection-observer";
// Components
import Content from "./Content";
// Containers
import { setReferrerSource } from "helpers/referrer";
// Static Queries
import "./staticQueries";

function Layout({ headerColor, children }) {
  useEffect(() => {
    setReferrerSource(window.location.search);
  }, []);

  const handleChannelSession = () => {
    const channel = new URLSearchParams(window.location.search).get("channel");

    if (channel) {
      localStorage.setItem("@tecimob/channel", channel);
      return false;
    }
  };

  useEffect(() => {
    handleChannelSession();
  }, []);

  return <Content headerColor={headerColor}>{children}</Content>;
}

Layout.defaultProps = {
  headerColor: "#fff",
  children: null,
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
