import styled from "styled-components";
import Link from "gatsby-link";
import { Breakpoints } from "config-styles";

export const List = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0px;
  padding: 0px;
  list-style: none;

  strong {
    text-decoration: underline;
    font-weight: 600;
  }
`;

export const ListTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

export const DropList = styled(List)`
  flex-wrap: wrap;
  background-color: #0051bb;
`;

export const ItemLink = styled(Link)`
  position: relative;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  & > svg {
    z-index: 2;
    width: 30px;
    height: 30px;
    background-color: #fff;
    color: #3e4759;
    margin-right: 10px;
    border-radius: 50%;
    padding: 8px;
  }
  & > div {
    z-index: 2;
    flex-direction: column;
  }
  & > div > strong,
  > div > p {
    margin-top: 2px;
    color: #fff;
  }
`;

export const ItemAnchor = styled(ItemLink)``;

export const ItemText = styled(ItemLink)`
  overflow: hidden;
  &:hover {
    overflow: inherit;
  }
  & > .drop-down {
    z-index: 0;
    visibility: hidden;
    transform: translateY(100%);
    position: absolute;
    left: 0;
    bottom: 100%;
    transition: all 0.5s;
  }
  &:hover > .drop-down {
    transition: all 0.5s;
    transform: translateY(0);
    visibility: visible;
  }
`;

export const ItemBordered = styled.li`
  position: relative;
  &::after {
    content: "";
    width: 1px;
    height: 30px;
    position: absolute;
    z-index: 5;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ffffffb8;
  }
`;

export const DropItem = styled.li`
  flex: 1 0 150px;
  flex-direction: column;
  color: #fff;
  padding: 15px;
  & > span {
    position: relative;
  }

  & > a {
    position: relative;
    cursor: pointer;
    color: #fff;

    &:hover {
      color: #9ec2f5;
    }
  }
  & > span > svg {
    position: absolute;
    top: 3px;
    left: 102%;
  }
`;

export const DropItemWhatsapp = styled.li`
  flex-direction: column;
  color: #fff;
  padding: 15px;

  & > span {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 5px;
    white-space: nowrap;
    transition: color 0.4s;
    color: white;
    flex-wrap: nowrap;
  }

  & > a {
    user-select: none;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 5px;
    white-space: nowrap;
    transition: color 0.4s;
    color: white;
    flex-wrap: nowrap;
    &:hover {
      color: #9ec2f5;
    }
  }
  & > a > svg {
    margin: 0px 5px;
  }
`;

export const DropDownContent = styled.div``;

export const ListItem = styled.li`
  position: relative;

  ::after {
    content: "";
    width: 1px;
    height: 35px;
    z-index: 5;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.2);
  }

  :first-of-type ::after {
    display: none;
  }
`;

export const DropDown = styled(ListItem)`
  & .DropDown__dropdown {
    z-index: 50;
  }
  & .DropDown__content {
    z-index: 0;
    transform: scale3d(0, 0, 0);
    position: absolute;
    left: 0;
    bottom: 100%;
    transition: all 0.4s;
    transform-origin: 50% 100%;
    perspective: 5px;
  }
  &:hover .DropDown__content {
    transform: scale3d(1, 1, 1);
    visibility: visible;
  }
`;

export const BlockBar = styled.div`
  width: 100%;
  height: 56px;
`;

export const Bar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: #0051bb;
  justify-content: center;
  display: flex;
  z-index: 50;

  ${Breakpoints.mobile} {
    justify-content: flex-start;

    .h-hide-mobile {
      display: none;
    }
  }

  ${Breakpoints.tabletMax} {
    .h-hide-tablet {
      display: none;
    }

    ${DropDown}::after {
      display: none;
    }
  }

  * {
    display: flex;
    text-decoration: none;
  }
`;

export const Phone = styled.span`
  font-family: Arial;
  margin-right: 5px;
`;
