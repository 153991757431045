import React from "react";
import styled from "styled-components";
import { string } from "prop-types";
// Components
import problem from "./problem.svg";

const Wrapper = styled.div`
  text-align: center;

  img {
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 0.2em;
  }

  p {
    margin-bottom: 15px;
  }
`;

const Image = styled.img`
  width: 80px;
`;

function ModalEmergence() {
  return (
    <Wrapper>
      <Image src={problem} alt="Problema" />
      <h3>
        Estamos trabalhando em uma manutenção emergencial em nossos servidores.
        Por favor, tente novamente mais tarde.
      </h3>
    </Wrapper>
  );
}

ModalEmergence.defaultProps = {
  dateEnd: null,
  urlSite: null
};

ModalEmergence.propTypes = {
  dateEnd: string,
  urlSite: string
};

export default ModalEmergence;
