import React, { useRef, useState, useEffect } from "react";
import { Link } from "gatsby";
import { MdPhone, MdEmail, MdBusinessCenter } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";

import {
  Phone,
  BlockBar,
  Bar,
  List,
  ListTitle,
  ItemLink,
  ItemText,
  DropList,
  DropItem,
  DropDown,
  ListItem,
} from "./styles";
import ChatBox from "components/ChatBox";
import { isChatVisible } from "components/ChatBox/helpers";
import { shareWhatsapp } from "helpers/share-helpers";
import useLocalStorage from "hooks/useLocalStorage";

function ContactBar() {
  const contactBar = useRef();
  const [isVisible, setIsChatVisible] = useState(false);
  const [isOpen, setIsOpen] = useLocalStorage("@tecimob/popover", true);

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    if (isChatVisible()) {
      setIsChatVisible(true);
    }
  }, []);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <>
      {isVisible && (
        <ChatBox
          isOpen={isOpen}
          isBoxOpen={scrollTop > 50}
          handleClose={() => setIsOpen(false)}
        />
      )}
      {/*<Bar id="contact-bar" ref={contactBar}>*/}
      {/*  <List>*/}
      {/*    <ListTitle className="h-hide-mobile">Podemos ajudar?</ListTitle>*/}
      {/*    <DropDown>*/}
      {/*      <ItemText*/}
      {/*        className="DropDown__dropdown"*/}
      {/*        as="a"*/}
      {/*        target="_blank"*/}
      {/*        href={shareWhatsapp("554896049333")}*/}
      {/*        rel="noopener noreferrer"*/}
      {/*      >*/}
      {/*        <FaWhatsapp*/}
      {/*          style={{ backgroundColor: "#25d366", color: "white" }}*/}
      {/*        />*/}
      {/*        <div>*/}
      {/*          <p>Falar por</p>*/}
      {/*          <strong>WhatsApp</strong>*/}
      {/*        </div>*/}
      {/*      </ItemText>*/}
      {/*    </DropDown>*/}
      {/*    <DropDown className="h-hide-tablet">*/}
      {/*      <ItemText className="DropDown__dropdown" as="div">*/}
      {/*        <MdPhone />*/}
      {/*        <div>*/}
      {/*          <p>Ligue para nós</p>*/}
      {/*          <strong>Ver números</strong>*/}
      {/*        </div>*/}
      {/*      </ItemText>*/}
      {/*      <DropList className="DropDown__content" style={{ width: 330 }}>*/}
      {/*        <DropItem>*/}
      {/*          <span>Vivo</span>*/}
      {/*          <span>*/}
      {/*            <Phone>(48) 9-9116-1213</Phone>*/}
      {/*          </span>*/}
      {/*          <span>*/}
      {/*            <Phone>(48) 9-9125-1213</Phone>*/}
      {/*          </span>*/}
      {/*        </DropItem>*/}
      {/*        <DropItem>*/}
      {/*          <span>Claro</span>*/}
      {/*          <span>*/}
      {/*            <Phone>(48) 9-8814-0223</Phone>*/}
      {/*          </span>*/}
      {/*          <span>*/}
      {/*            <Phone>(48) 9-8846-7888</Phone>*/}
      {/*          </span>*/}
      {/*        </DropItem>*/}
      {/*        <DropItem>*/}
      {/*          <span>Tim</span>*/}
      {/*          <span>*/}
      {/*            <Phone>(48) 9-9679-1101</Phone>*/}
      {/*          </span>*/}
      {/*        </DropItem>*/}

      {/*        <DropItem>*/}
      {/*          <span>Oi</span>*/}
      {/*          <span>*/}
      {/*            <Phone>(48) 9-8419-8187</Phone>*/}
      {/*          </span>*/}
      {/*        </DropItem>*/}
      {/*      </DropList>*/}
      {/*    </DropDown>*/}
      {/*    <ListItem className="h-hide-tablet">*/}
      {/*      <ItemLink to="/suporte/">*/}
      {/*        <MdEmail />*/}
      {/*        <div>*/}
      {/*          <p>Fale conosco</p>*/}
      {/*          <strong>Envie uma mensagem</strong>*/}
      {/*        </div>*/}
      {/*      </ItemLink>*/}
      {/*    </ListItem>*/}
      {/*    <ListItem className="h-hide-tablet">*/}
      {/*      <ItemText to="/planos/" className="DropDown__dropdown" as={Link}>*/}
      {/*        <MdBusinessCenter />*/}
      {/*        <div>*/}
      {/*          <strong>Monte o plano</strong>*/}
      {/*          <strong>Ideal para você</strong>*/}
      {/*        </div>*/}
      {/*      </ItemText>*/}
      {/*    </ListItem>*/}
      {/*  </List>*/}
      {/*</Bar>*/}
      {/*<BlockBar />*/}
    </>
  );
}

export default ContactBar;
